// Fonts
@import url('https://fonts.googleapis.com/css?family=Nunito');

// Variables
@import 'variables';

// Bootstrap
@import '~bootstrap/scss/bootstrap';

@import 'node_modules/swiper/swiper';
@import 'node_modules/swiper/modules/navigation/navigation';
@import 'node_modules/swiper/modules/pagination/pagination';

@import "~aos/dist/aos.css";
@import 'boxicons';

@import '~bootstrap-icons/font/bootstrap-icons';
@import '~flag-icon-css/sass/flag-icon';
